<template>
  <b-container fluid class="search-content p-100">
    <b-row class="mt-4 z-index-10000">
      <b-col>
        <b-button class="font-sfns text-right note-primary-btn" :to="{name: 'city', params: {cityId: $route.query.city}, query:{plan: $route.query.plan}}" pill block variant="primary">{{ $route.query.plan }}</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col class="text-right">
        <h4>ملاحظة شخصية (اختياري)</h4>
      </b-col>
    </b-row>
    <div class="note-cover mt-4">
      <textarea v-model="comment" class="text-right" placeholder="اضف ملاحظاتك هنا…."></textarea>
      <b-button :disabled="btnDisabled" @click="addPlan" class="text-center note-sub-btn" size="lg" pill block variant="success">حفظ</b-button>
    </div>
    <success-modal :disabled="disabledModal" />
  </b-container>
</template>

<script>
import SuccessModal from '@/components/SuccessModal.vue'

export default {
  title: "المخططات المفضلة",
  data() {
    return {
      height: "18px",
      comment: "",
      success: false
    }
  },
  components: {
    SuccessModal
  },
  watch: {
    '$route.query'() {
      this.fetchPlan()
    },
    success(val) {
      this.success = val
    }
  },
  computed: {
    disabledModal() {
      return this.success
    },
    btnDisabled() {
      return this.comment == ""
    },
  },
  methods: {
    toggleSuccess() {
      this.success = true
    },
    fetchPlan() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          city_id: this.$route.query.city,
          q: this.$route.query.plan
        }
      }
      this.$store.dispatch("plan/fetchPlan", payload)
      .then(() => {
        loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    },
    addPlan() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          plan_id: this.$route.params.planId,
          comment: this.comment
        }
      }
      this.$store.dispatch("plan/addFavouritePlan", payload)
      .then(() => {
        this.comment = ""
        this.toggleSuccess()
        loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    }
  },
  created() {
    this.fetchPlan()
  },
  updated() {
  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100vh;
}
</style>
